<template>
  <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Descontos">
      <crud-base
        :url="url"
        :columns="columns"
        :onDelete="() => {}"
        :onUpdate="() => {}"
        :onStore="() => {}"
        :enableSearch="true"
        :formInputs="formInputs"
        :checkAlerts="true"
        primaryKey="id"
      />
    </CTab>
  </CTabs>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {
  schemaBooleanSelect,
  schemaDatePicker,
  schemaNumber,
  schemaPercent,
  schemaTextField
} from "@/helpers/form";
import {dateFilter} from "@/filters/dateFilter";
import {formatPercent} from "@/filters/monetary";
import {yesNoRepresentation} from "@/helpers/transformers";
export default {
  name: "descontos",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/descontos",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "Descrição", field: "descricao", sortable: false },
        { label: "Dt. Início", field: "dtinicio", sortable: false, representedAs: (row) => dateFilter(row.dtinicio) },
        { label: "Dt. Final", field: "dtfim", sortable: false, representedAs: (row) => dateFilter(row.dtfim) },
        { label: "Perc. Desc", field: "percdesconto", sortable: false, representedAs: (row) => formatPercent(row.percdesconto) },
        { label: "Ativo", field: "ativo", sortable: false, representedAs: (row) => yesNoRepresentation(row.ativo) },
      ],
      formInputs: {
        id: schemaTextField('Cód', true, 2),
        ativo: schemaBooleanSelect('Ativo', true, 2, true, 1),
        aplicacallcenter: schemaBooleanSelect('Exibe Call?', true, 2, true, 1),
        aplicab2b: schemaBooleanSelect('Exibe B2B?', true, 2, true, 1),
        aplicafv: schemaBooleanSelect('Exibe FV?', true, 2, true, 1),
        aplicaautomatico: schemaBooleanSelect('Apli. Automático?', true, 2, true, 0),

        "_line1": {
          "type": "HTML",
          "value": "<strong>Regras de ativação</strong><hr/>"
        },

        percdesconto: schemaPercent('% Desc.', true, 2),
        percmaximodesc: schemaPercent('Perc. Máx Desc', false, 2),
        qtdmin: schemaNumber('Qtd. Min', true, 2, 0),
        qtdmax: schemaNumber('Qtd. Máx', false, 2),
        dtinicio: schemaDatePicker('Dt. início', true, 2),
        dtfim: schemaDatePicker('Dt. fim', true, 2),

        politica_id: schemaTextField('Cód. Política', false, 2),
        prioritaria: schemaBooleanSelect('Prioritária?', true, 2, true, 0),
        prioritariageral: schemaBooleanSelect('Pri. Geral?', true, 2, true, 0),
        somenteretira: schemaBooleanSelect('Somente retira?', true, 2, true, 0),
        planopagamento_id: schemaTextField('Cód. Plano', false, 2),
        multiplo: schemaNumber('Múltiplo', false, 2),

        visivel: schemaBooleanSelect('Visível ao cliente?', true, 3, true, 1),
        exibeflag: schemaBooleanSelect('Exibe flag?', true, 3, true, 1),
        alteraprecotabela: schemaBooleanSelect('Altera Preço Tabela?', true, 3, true, 0),

        "_line2": {
          "type": "HTML",
          "value": "<strong>Validações de conta corrente</strong><hr/>"
        },
        creditacontacorrente: schemaBooleanSelect('Credita CC ao RCA se não conceder o desconto total?', true, 6, true, 0),
        politicacontacorrente: schemaBooleanSelect('Debita CC do RCA se conceder o desconto?', true, 6, true, 0),

        "_line3": {
          "type": "HTML",
          "value": "<strong>Restrições por cliente</strong><hr/>"
        },
        filial_id: schemaTextField('Cód. Filial', false, 3),
        regiao_id: schemaTextField('Cód. Região', false, 3),
        praca_id: schemaTextField('Cód. Praça', false, 3),
        rede_id: schemaTextField('Cód. Rede', false, 3),
        atividade_id: schemaTextField('Cód. Atividade', false, 3),

        cliente_id: schemaTextField('Cód. Cliente', false, 3),
        supervisor_id: schemaTextField('Cód. Superv.', false, 3),
        representante_id: schemaTextField('Cód. RCA', false, 3),
        validausuario: schemaBooleanSelect('Valida Cód. RCA?', false, 3, true, 0),

        "_line4": {
          "type": "HTML",
          "value": "<strong>Restrições por produto</strong><hr/>"
        },
        produto_id: schemaTextField('Cód. Produto', false, 3),
        produtoprincipal_id: schemaTextField('Cód. Prod. Principal', false, 3),
        fornecedor_id: schemaTextField('Cód. Fornecedor', false, 3),
        dimensao1: schemaTextField('Cód. Dim. 1', false, 3),
        dimensao2: schemaTextField('Cód. Dim. 2', false, 3),
        dimensao3: schemaTextField('Cód. Dim. 3', false, 3),
        dimensao4: schemaTextField('Cód. Dim. 4', false, 3),
        dimensao5: schemaTextField('Cód. Dim. 5', false, 3),
        dimensao6: schemaTextField('Cód. Dim. 6', false, 3),
        dimensao7: schemaTextField('Cód. Dim. 7', false, 3),
        dimensao8: schemaTextField('Cód. Dim. 8', false, 3),
        dimensao9: schemaTextField('Cód. Dim. 9', false, 3),
        dimensao10: schemaTextField('Cód. Dim. 10', false, 3)
      },
    };
  },
};
</script>
